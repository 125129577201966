<template>
    <div class="panel has-text-weight-semibold">
        <div class="panel-heading has-background-grey-lighter has-text-weight-bold">
            <div class="columns is-mobile">
                <div class="column is-size-6 has-text-centered">STORES STATUS</div>
            </div>
        </div>
        <hr>
        <b-table 
            :data='stores'
            :paginated="isPaginated"
            :per-page="perPage"
            paginationPosition="both"
            sortIcon="arrow-up"
            sortIconSize="is-small"
            default-sort=storeDeviceStatus
            defaultSortDirection='desc'
            >

            <template>

                <b-table-column field="id" label="Store ID" v-slot="props" sortable sorticon>
                    {{ props.row.id }}
                </b-table-column>
                <b-table-column field="storeName" label="Store" v-slot="props" searchable sortable sorticon>
                    {{ props.row.storeName }}
                </b-table-column>
                <b-table-column field="active" label="Status" v-slot="props" sortable sorticon>
                    <span :class="{'has-text-danger': ! props.row.active, 'has-text-success': props.row.active}">{{ props.row.active ? "✓" : "✗" }}</span>
                </b-table-column>                
                <b-table-column field="verifoneSubscription" label="POS" v-slot="props" sortable sorticon>
                    <span :class="{'has-text-danger': ! props.row.verifoneSubscription, 'has-text-success': props.row.verifoneSubscription}">{{ props.row.verifoneSubscription ? "✓" : "✗" }}</span>
                </b-table-column>
                <b-table-column field="veederSubscription" label="ATG" v-slot="props" sortable sorticon>
                    <span :class="{'has-text-danger': ! props.row.veederSubscription, 'has-text-success': props.row.veederSubscription}">{{ props.row.veederSubscription ? "✓" : "✗" }}</span>
                </b-table-column> 
                <b-table-column field="bankSubscription" label="Bank" v-slot="props" sortable sorticon>
                    <span :class="{'has-text-danger': ! props.row.bankSubscription, 'has-text-success': props.row.bankSubscription}">{{ props.row.bankSubscription ? "✓" : "✗" }}</span>
                </b-table-column>                 
                <b-table-column field="deviceId" label="Device ID" v-slot="props" sortable sorticon>
                    {{ props.row.deviceId }}
                </b-table-column>
                <b-table-column field="deviceOsVersion" label="Firmware" v-slot="props" sortable sorticon>
                    {{ props.row.deviceOsVersion }}
                </b-table-column>
                <b-table-column field="registerExpirationDate" label="Password Expire" v-slot="props" sortable sorticon>
                    {{ props.row.registerExpirationDate | formatDate }}
                </b-table-column>                  
                <b-table-column date field="lastHeartBeat" label="Last Heart Beat" v-slot="props" sortable sorticon>
                    <!-- {{ new Date(props.row.lastHeartBeat*1000).toLocaleDateString("en-US") |formatDateTime }} -->
                    {{ getTimeStamp(props.row) | formatDateTime }}
                </b-table-column> 
                <b-table-column field="storeDeviceStatus" label="Connectivity" v-slot="props"  sortable sorticon>
                       {{ props.row.storeDeviceStatus == 'OK' ? "🟢" : "🔴" }}
                </b-table-column>                                                                                                   
            </template>
            <template slot="empty">
                <data-loading 
                    :isLoading="isLoading" 
                    :isFullPage="isFullPage"> 
                </data-loading>
            </template>

        </b-table>

    </div>
</template>

<script>
import UserStore from "../../store/UserStore"

import DataLoading from '../../components/app/DataLoading.vue'

import axios from 'axios'


export default {

    components: {
        DataLoading,
    },

    metaInfo: {
        title: 'Stores Status Report'
    },

    data() {
        return {
            stores: [],
            isPaginated: true,
            perPage: 10,
            isLoading: false,
            isFullPage: false,
            isShowDialog: false,
            user: {},
            storesStatusAllowedUsers: [
                'aby,thomas@veecli.com', 
                'saran.krishnan@veecli.com',
                'support@veecli.com',
                'aby@tsits.com'
                ],            

        }
    },

    methods: {

        fetchRecords() {
            if (this.user.activeStore) {
                const url = process.env.VUE_APP_API_ROOT_URL + 'stores/?size=1000'
                this.isLoading = true
                axios.get(url,
                    {
                        headers: {
                            'Authorization': 'Bearer ' + UserStore.getters.getAccessToken,
                            'Refresh-Token': UserStore.getters.getRefreshToken
                        },                   
                    })
                    .then( (response) => {
                        if (response.data.accessToken) UserStore.commit('setAccessToken',response.data.accessToken)
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(response.data)
                        this.stores = response.data.data

                        this.stores = this.stores.filter((item) => item.active )

                        this.isLoading = false
                    })
                    .catch( (err) => {
                        if (process.env.VUE_APP_DEBUG == 'true') console.log(err.response.status, err.response.data)
                        this.isLoading = false
                        if (err.response.status === 403) {
                            alert('Session Expired. Please log in again!')
                            UserStore.commit('userLogout')
                        }
                    })
            }
            
        },

        getTimeStamp(row) {

            if (row.lastHeartBeat !== null) {
                var date = new Date(row.lastHeartBeat*1000)
                // return date.toLocaleDateString("en-US") + ' ' + date.toLocaleTimeString("en-US")
                return date
            } else {
                return null
            }
        }
    },

    computed: {
        // getUser() {
        //     return UserStore.getters.getUser
        // },

        getActiveStore() {
            return UserStore.getters.getActiveStore
        },

        storesStatusAllowedUser() {
        
          return this.storesStatusAllowedUsers.includes(this.user.email)
          
        },        
    },

    mounted() {
        this.user = this.getUser()
        this.stores = []
        if (this.storesStatusAllowedUser) {
            this.fetchRecords()
        } else {
            this.$router.push('/notauthorized')
        }
    }

}
</script>

<style>
tr:nth-child(even) {
  background-color: #f2f2f2;
}
</style>